<template>
    <b-container>
        <div id="indexpage">

            <div id="flex-cont">

                <div id="flex-item">
                    <img alt="Vue logo" src="./../assets/hotel1.jpg" rounded>
                    
                </div>
                
                <div id="flex-item-details">
                    <h4>MAKE YOUR RESERVATION</h4>
                    
                    
                    <label>Check In</label>
                    <b-form-datepicker id="datepicker-sm" size="sm" locale="en" class="mb-2"></b-form-datepicker>

                    <label>Check Out</label>
                    <b-form-datepicker id="datepicker-sm" size="sm" locale="en"  class="mb-2"></b-form-datepicker>

                    <label>Adults</label>
                    <b-form-select v-model="selected" :options="options"></b-form-select>

                    <label>Children</label>
                    <b-form-select v-model="selected" :options="options"></b-form-select>
                    

                    <b-button size="lg" variant="info"><router-link to="/rooms">CHECK AVAILABILITY</router-link></b-button>

                </div>
            </div>

            <hr/>
        
            <div id="gridindex">
                <h1 id="sub-head"> Check Out Our Luxury Rooms </h1>
                <b-row>
                    <b-col>
                        <h4><router-link to="/rooms">Classic Deluxe</router-link></h4>
                        <img id="roompics" alt="Vue logo" src="./../assets/ClassicDeluxe.jpg">
                    </b-col>
                    <b-col>
                        <h4><router-link to="/rooms">Presidential Suite</router-link></h4>
                        <img id="roompics" alt="Vue logo" src="./../assets/PresidentialSuite.jpg">
                    </b-col>
                    <b-col>
                        <h4><router-link to="/rooms">Ambassador Suite</router-link></h4>
                        <img id="roompics" alt="Vue logo" src="./../assets/AmbassadorSuite.jpg">
                    </b-col>
                </b-row>

                <hr/> 

            </div>

            <hr/>

            <div>
                <h1 id="sub-head"> Great Discounts Awaits When You </h1>
                <b-row>
                    <b-col>
                        <h3>Join Our Gym Club</h3>
                        <img id="clubsImg" src="./../assets/gym2.jpg">
                    </b-col>
                    <b-col>
                        <h3>Join Art Membership Club</h3>
                        <img id="clubsImg" src="./../assets/ArtClub.jpg">
                    </b-col>
                </b-row>
            </div>

            <hr/>

            <div>
                <b-row>
                    <b-col class="col-md-2">
                        <h3>FOLLOW US <br> &rarr; </h3>
                    </b-col>
                    <b-col class="col-md-2">
                        <img src="./../assets/FaceBookICON1.jpg" class="icons">
                        <img src="./../assets/InstagramICON1.png" class="icons">
                        <img src="./../assets/twitterICON3.jpeg" class="icons">
                        <img src="./../assets/linkedInICON1.png" class="icons">
                    </b-col>
                    <b-col class="col-md-2">
                        <h5>About CH</h5>
                        <h5>Blog</h5>
                        <h5>Experience</h5>
                    </b-col>
                    <b-col class="col-md-2">
                        <h5>Privacy policy</h5>
                        <h5>Write Us</h5>
                        <h5>Loyalty programs</h5>
                    </b-col>
                    <b-col class="col-md-4">
                        <h5>Plot 95, Kofo Abayomi street, <br> Victoria island, Lagos, Nigera, 23401</h5>
                        <h5>Tel: +2348049483026</h5>
                        <h5>Tel: +2348049483026</h5>
                    </b-col>

                </b-row>
            </div>
        </div>
    </b-container>

</template>

<script>
  export default {
    data() {
      return {
        selected: null,
        options: [
          { value: 'a', text: '1' },
          { value: 'b', text: '2' }
        ]
      }
    }
  }
</script>




<style scoped>
#row1{
    width: 100%;
}
#indexpage{
    margin-top: 3%;
    margin-bottom: 3%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
#gridindex{
    margin-top: 2%;
    margin-left: 5%;
    
}
#flex-cont{
        display: flex;
        flex-direction: row;
        
}
#flex-item{
        width: 70%;
        margin-right: 30px;
    }
    #flex-item-details{
        width: 30%;
        border-radius: 10px;
        margin-left: 13px;
        padding: 5px;
        float: left;
        background-color: aqua;

    }
    img{
        width: 100%;
        border-radius: 10px;
    
    }
    #datepicker-sm{
        font-weight: bold;
    }
    #roompics{
        width: 300px;
        height: 350px;
    }
    #sub-head{
        font-weight: bold;
    }
    #clubsImg{
        width: 85%;
        height: 500px;
    }
    .icons{
        width: 50px;
        height: 50px;
    }

  

</style>