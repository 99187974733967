import Vue from 'vue'
import App from './App.vue'

//Import Router
import VueRouter from 'vue-router'
import Routes from './routes'

//Import Bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueRouter);

const router=new VueRouter({
  routes:Routes
});

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')
