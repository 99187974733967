import IndexPage from './components/IndexPage.vue';
import AboutPage from './components/AboutPage.vue';
import ContactPage from './components/ContactPage.vue';
import RoomsPage from './components/RoomsPage.vue';
import CheckoutPage from './components/CheckoutPage.vue';
import DinningPage from './components/DinningPage.vue';


export default[
    {path:'/', component:IndexPage},
    {path:'/checkout', component:CheckoutPage},
    {path:'/about', component:AboutPage},
    {path:'/contact', component:ContactPage},
    {path:'/rooms', component:RoomsPage},
    {path:'/dinning', component:DinningPage},
    
]